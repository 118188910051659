<template>
<div>
  <el-row>
    <el-col>
      <head-layout
        :head-btn-options="headBtnOptions"
        :head-title="$t(`cip.plat.app.attendance.title.indexHeadTitle`)"
        @head-remove="headRemove"
        @head-add-tabs="headAddTabs"
      ></head-layout>
    </el-col>
  </el-row>

  <grid-head-layout
    ref="gridHeadLayout"
    :search-columns="searchColumns"
    @grid-head-search="gridHeadSearch"
    @grid-head-empty="gridHeadEmpty"
  ></grid-head-layout>

  <grid-layout
    ref="gridLayOut"
    :grid-row-btn="gridRowBtn"
    :table-options="tableOptions"
    :table-data="tableData"
    @gird-handle-select-click="selectionChange"
    :table-loading="tableLoading"
    :data-total="page.total"
    :page="page"
    @page-current-change="onLoad"
    @page-size-change="onLoad"
    @page-refresh-change="onLoad"
    @grid-row-detail-click="rowDetail"
    @row-remove="rowRemove"
    @row-edit="rowEdit"
  >
    <template #customBtn="{row}">
      <el-button
        type="text"
        size="small"
        @click="rowEnable(row)"
      >{{ row.status == 2 ? $t('cip.cmn.btn.validBtn') :$t('cip.cmn.btn.disable')}}
      </el-button>
    </template>
<!--    <template #customBtn="{row}">-->
<!--      <el-button-->
<!--        v-if="row.status == 1"-->
<!--        type="text"-->
<!--        size="small"-->
<!--        @click="rowDisable(row)"-->
<!--      >{{ $t('sinoma.btn.disable') }}-->
<!--      </el-button>-->
<!--    </template>-->

  </grid-layout>
</div>
</template>


<script>
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import {add,getPage,remove} from "@/api/system/attendance";
import {mapGetters} from "vuex";

export default {
name: "attendance",
  components: {
    GridLayout,
    HeadLayout,
  },
  data() {
    return {
      selectionList: [],
      tableLoading: true,
      tableData: [],
      dataTotal: null,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    searchColumns() {
      return [
        {
          prop: "address",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t("cip.plat.app.attendance.field.address"),
        },
      ]
    },
    tableOptions() {
      return {
        height: 0,
        menuWidth: 220,
        customAddBtn: true,
        column: [
          {
            label: this.$t('cip.plat.app.attendance.field.code'),
            prop: 'code',
            width: 240,
            align: "left",
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.app.attendance.field.address'),
            prop: 'address',
            align: "left",
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.app.attendance.field.longitude'),
            prop: 'longitude',
            align: "center",
            width: 130,
            overHidden: true
          },
          {
            label: this.$t('cip.plat.app.attendance.field.dimension'),
            prop: 'dimension',
            width: 130,
            align: "center",
            overHidden: true
          },
          {
            label: this.$t('cip.plat.app.attendance.field.clockRange'),
            prop: 'clockRange',
            width: 100,
            align: "right",
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.app.attendance.field.workShiftTime'),
            prop: 'workShiftTime',
            align: "center",
            overHidden: true,
            width: 130,
          },
          {
            label: this.$t('cip.plat.app.attendance.field.workUnshiftTime'),
            prop: 'workUnshiftTime',
            align: "center",
            overHidden: true,
            width: 130,
          },
        ],
      }
    },
    gridRowBtn() {
      let result = [];
      //新增按钮
      if (this.permission.attendance_edit) {
        result.push({
          label: this.$t('cip.cmn.btn.editBtn'),
          emit: "row-edit",
          type: "button",
          icon: ""
        });
      }
      if (this.permission.attendance_delete) {
        result.push({
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "row-remove",
          type: "button",
          icon: ""
        });
      }
      return result
    },
    headBtnOptions() {
      let result = [];
      //新增按钮
      if (this.permission.attendance_add) {
        result.push({
          label: this.$t('cip.cmn.btn.addBtn'),
          emit: "head-add-tabs",
          type: "button",
          icon: "",
          btnOptType: 'add',
        });
      }
      if (this.permission.attendance_delete) {
        result.push({
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "head-remove",
          type: "button",
          icon: "",
          btnOptType: 'remove',
        });
      }
      return result
    },
  },
  mounted() {
    this.onLoad(this.page, {});
  },
  methods: {
    rowEnable(row){
      if(row.status == 1){
        row.status = 2
      }else if(row.status == 2){
        row.status = 1
      }
      add(row).then(res=>{
        this.onLoad(this.page,{})
      })
    },
    rowDisable(row){
      row.status = 2
      add(row).then(res=>{
        this.onLoad(this.page,{})
      })
    },
    selectionChange(list) {
      this.selectionList = list;
      console.log(this.selectionList)
    },
    headRemove(){
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
        return;
      }
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          let ids=this.selectionList.map(item=>{return item.id}).join(',')
          return remove(ids);
        })
        .then(() => {
          // 刷新表格数据并重载
          this.data = [];
          this.parentId = 0;
          this.$refs.gridLayOut.selectionClear();
          // 表格数据重载
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
        });
    },
    headAddTabs() {
      this.$router.push({
        path: `/attendance/add`,
        query: {
          type: 'add',
          pageType: 'add',
        }
      })
    },
    rowEdit(row) {
      this.$router.push({
        path: `/attendance/add`,
        query: {
          id: row.id,
          type: 'edit',
          pageType: 'edit',
        }
      })
    },

    rowDetail(row) {
      this.$router.push({
        path: `/attendance/add`,
        query: {
          id: row.id,
          type: 'view',
          pageType: 'detail',
        }
      })
    },

    rowRemove(row) {
      this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess')
          });
        });
    },
    gridHeadSearch(query) {
      this.onLoad(this.page, query)
    },
    gridHeadEmpty(query) {
      this.onLoad(this.page, query)
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      getPage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.$refs.gridHeadLayout.searchForm)
      ).then(res => {
        const data = res.data.data;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records;
        this.tableData.forEach((item) => {
          item.$cellEdit = false;
        })
        console.log(this.tableData,'292')
        this.tableLoading = false;
      });
    }
  }
}
</script>

<style scoped>

</style>
